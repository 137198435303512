<template>
    <button
        :to="isTo"
        @click.prevent="$emit('add', true)"
        class="sidebar__trigger sidebar__trigger--add"
    >
        <icon width="10" height="10">
            <path
                d="M1 5H9"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
            />

            <path
                d="M5 9L5 1"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
            />
        </icon>
    </button>
</template>

<script>
    import Icon from '@/components/icon/Icon'

    export default {
        name: 'TriggerAdd',
        components: {
            Icon
        },
        props: {
            isTo: {
                default: ''
            }
        }
    }
</script>

<style lang="scss">
    .sidebar__trigger {
        &--add {
            display: flex;
            align-items: center;
            justify-content: center;

            z-index: 1;
            border: none;
            padding: 5px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #828798;
            transition: all 0.05s ease-in;

            &:hover {
                background-color: darken(#828798, 5%);
            }
        }
    }
</style>
